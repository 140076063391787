
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IMarca } from '@/models/Entidades/IMarca';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import Seo from '@/components/Seo.vue';
import ServicoMarca from '@/servicos/ServicoMarca';
import { IResposta } from '@/core/models/IResposta';
import { IEstrutura } from '@/models/Entidades/IEstrutura';
import { ETipoEstrutura } from '@/models/Enumeradores/ETipoEstrutura';

export default defineComponent({
  name: 'MarcaCadastro',
  components: {
    ComunicacaoApi,
    EditorHtml,
    Seo,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoMarca = new ServicoMarca();

    const state = reactive({
      marca: {} as IMarca,
    });

    async function obterMarca(codigo:number) {
      appBase.carregando = true;
      state.marca = await servicoMarca.obter(codigo);
      appBase.carregando = false;
    }

    function limparDados() {
      state.marca = {} as IMarca;
      state.marca.codigo = 0;
      state.marca.status = true;
      state.marca.estrutura = {} as IEstrutura;
      state.marca.estrutura.codigo = 0;
      state.marca.estrutura.indexar = true;
      state.marca.estrutura.tipo = ETipoEstrutura.Marca;
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoMarca = obterCodigoRota();
      if (codigoMarca > 0) {
        await obterMarca(codigoMarca);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da Marca.');
      if (state.marca.codigo === 0) {
        state.marca.estrutura.url = 'url';
        appBase.resposta = await servicoMarca.incluir(state.marca);
      } else {
        appBase.resposta = await servicoMarca.atualizar(state.marca);
      }

      apresentarRespostaRedirecionamento('Marcas');
    }
    return {
      appBase,
      state,
      salvar,
    };
  },
});
